<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="标签管理"
        :mask-closable="false"
        footer-hide
    >
        <div>
            <Input v-model="query" class="margin-right" clearable placeholder="搜索标签" style="width: auto" @on-change="searchNew" >
                <Icon type="ios-search" slot="suffix" />
            </Input>
            <Button shape="circle" icon="ios-add" @click="handelAddLabel"></Button>
            <load-more :onRefresh="handleReachBottom" :disabled="isLoaded" height="200px">
                <Dropdown class="dropdown line-overflow" v-for="item in list" :key="item.rowid" trigger="hover" placement="bottom-start" transfer >
                        <Button class="label" shape="circle">
                            {{ item.name }}
                            <Icon class="label-more" type="md-more" />
                        </Button>
                    <DropdownMenu slot="list">
                        <DropdownItem @click.native="handleRename(item)">重命名</DropdownItem>
                        <DropdownItem @click.native="handleRemove(item.rowid)">删除</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </load-more>
        </div>
        <add-label-modal
            :show="addLabelModalShow"
            :bucket_token="bucket_token"
            :type="type"
            :label_type="label_type"
            :editInfo="editInfo"
            @confirm="handleAddLabelSubmit"
            @cancel="handleAddLabelCancel"
        ></add-label-modal>
    </Modal>
</template>
<script>
    import addLabelModal from './addLabel'
    import loadMore from '@/views/components/loadMore'

    export default {
        name: "",
        mixins: [],
        components: {
            addLabelModal,
            loadMore
        },
        props: {
            show: {
                default: false
            },
            bucket_token: {
                default: null
            },
            label_type: {
                default: 'material'
            }
        },
        data () {
            return {
                modal: false,
                query: '',
                addLabelModalShow: false,
                list: [],
                current: 1,
                pageSize: 100,
                type: 'add',
                editInfo: {},
                searching: false,
                isLoaded: false
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.searchNew()
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.current = 1
                this.query = ''
                this.list = []
                this.editInfo = {}
                this.$emit('cancel')
            },
            searchNew() {
                this.current = 1
                this.list = []
                this.getData().then((data) => {
                    this.list = data
                })
            },
            handelAddLabel() {
                this.type = 'add'
                this.addLabelModalShow = true
            },
            handleAddLabelSubmit() {
                this.$emit('refresh')
                this.handleAddLabelCancel()
                this.searchNew()
                if (this.type === 'edit') {
                    this.$emit('clear')
                }
            },
            handleAddLabelCancel() {
                this.addLabelModalShow = false
            },
            handleRename(item) {
                this.type = 'edit'
                this.addLabelModalShow = true
                this.editInfo = item
            },
            handleRemove(rowid) {
                this.$Modal.confirm({
                    title: '删除标签',
                    content: '是否删除标签？删除后含有该标签的内容不会被删除。',
                    okText: '确定',
                    cancelText: '取消',
                    loading: true,
                    onOk: () => {
                        this.$api.delete(`labels/${rowid}?bucket_token=${this.bucket_token}&label_type=${this.label_type}`).then(()=>{
                            this.$Modal.remove()
                            this.$Message.success(this.$t('message.success'));
                            this.current = 1
                            this.list = []
                            this.getData()
                            this.$emit('refresh')
                            this.$emit('clear')
                        })

                    }
                });
            },
            getData() {
                let params = {
                    query: this.query,
                    bucket_token: this.bucket_token,
                    label_type: this.label_type,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                this.searching = true
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.current += 1
                        this.list.push(...data)
                    }
                    if (this.list.length == total_count) {
                        this.isLoaded = true
                    } else {
                        this.isLoaded = false
                    }
                    return data
                }).finally(() => {
                    this.searching = false
                })
            },
            async handleReachBottom(done) {
                await this.getData()
                done()
            }

        },
        created() {}
    }
</script>
<style scoped lang="less">
@deep: ">>>";
.dropdown {
    margin: 10px 10px 0 0;
    .label {
        .label-more {
            opacity: 0;
            transition: all .3 ease-in;
        }
        &:hover {
            .label-more {
                opacity: 1;
                display: inline-block;
            }
        }
    }
}
</style>