<template>
    <div id="company-spaces" class="content">
        <div class="header">
            <Row>
                <Col span="12">
                    <h1 class="title">企业空间
                        ({{ total }})
                    </h1>
                </Col>
                <Col span="12" class="clearfix">
                    <Button v-allow="'content'" class="float-right" type="primary" @click="handleLabelMag">标签管理</Button>
                    <Button v-allow="'content'" class="float-right margin-right" type="primary" @click="handleCreateTmpl">创建模板</Button>
                    <Button v-allow="'content'" class="float-right margin-right" type="primary" @click="handleUpload">上传素材</Button>
                    <Input v-model="query" class="float-right margin-right" clearable placeholder="搜索当前库" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
        </div>
        <div class="main-content">
            <Row class="action-bar">
                <Col span="20">
                    <div v-if="checkAllGroup.length === 0">
                        <RadioGroup class="radio-group-button" v-model="type" type="button" @on-change="handleTypeList">
                            <Radio label="all">全部</Radio>
                            <Radio v-for="item in typeList" :label="item.material_type" :key="item.material_type">{{ item.text._ }}</Radio>
                        </RadioGroup>
                        <!-- <RadioGroup class="radio-group-button" v-model="materialClass" type="button" @on-change="getTypeList">
                            <Radio label="all">全部</Radio>
                            <Radio label="templates">
                                <Dropdown trigger="click">
                                    <a href="javascript:void(0)">
                                        模版
                                        <Icon type="ios-arrow-down"></Icon>
                                    </a>
                                    <DropdownMenu slot="list">
                                        <DropdownItem v-for="(item, index) in templateTypeList" :key="index" @click.native="handleTemplateType(item.type_list)">{{ item.class }}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Radio>
                            <Radio label="materials">素材</Radio>
                        </RadioGroup> -->
                        <Dropdown class="dropdown dropdown-label" trigger="click"  placement="bottom-start" >
                            <Icon v-if="label" class="close" type="ios-close-circle" @click="clearLabelList" />
                            <Button type="text">
                                标签<span v-if="label">:{{ label.name }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <load-more v-if="labelList.length !== 0"  :onRefresh="handleReachBottom" :disabled="isLoaded" height="165px">
                                    <Tooltip class="tooltip" :content="item.name" theme="light" placement="top-start" transfer v-for="(item, index) in labelList" :key="index" :delay="1000">
                                        <DropdownItem class="label line-overflow" @click.native="handleLabelList(item)">{{ item.name }}</DropdownItem>
                                    </Tooltip>
                                </load-more>
                            </DropdownMenu>
                        </Dropdown>
                        <!-- <Dropdown class="dropdown dropdown-type" trigger="click" placement="bottom-start" >
                            <Icon v-if="type" class="close" type="ios-close-circle" @click="clearTypeList" />
                            <Button type="text">
                                类型<span v-if="type">:{{ type.text._ }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem v-for="(item, index) in typeList" :key="index" @click.native="handleTypeList(item)">{{ item.text._ }}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown> -->
                        <Dropdown class="dropdown dropdown-structure" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                            <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList" />
                            <Button type="text">
                                组织架构
                                <div v-if="nodeList.length" class="tree line-overflow">
                                    <span>:</span>
                                    <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                        <span v-if="index !== nodeList.length - 1">,</span>
                                    </span>
                                </div>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div v-else>
                        <Button class="margin-right" type="primary" @click="handelLabel(checkAllGroup)">编辑标签</Button>
                        <Button type="primary" @click="handleRemove(checkAllGroup)">删除</Button>
                    </div>
                </Col>
                <Col span="4">
                    <Checkbox
                        v-allow="'content'"
                        v-if="list.length !== 0"
                        class="float-right all"
                        :indeterminate="indeterminate"
                        :value="checkAll"
                        @click.prevent.native="handleCheckAll">
                        <span v-if="checkAllGroup.length === 0">全选</span>
                        <span v-else>已选中{{ checkAllGroup.length }}个内容</span>
                    </Checkbox>
                </Col>
            </Row>
            <no-data v-show="list.length === 0" text="暂无素材"></no-data>
            <spaces-common
                v-show="list.length > 0"
                ref="spaces"
                from="company"
                :total="total"
                :current="current"
                :pageSize="pageSize"
                :list="list"
                :loading="loading"
                :checkAllGroup="checkAllGroup"
                :handleCopyToCurrent="handleCopyToCurrent"
                :handleEditTmpl="handleEditTmpl"
                @nextPage="nextPage"
                @rename="submitRename"
                @checkAllGroupChange="checkAllGroupChange"
                @refresh="getData"
                @handleRefreshLabel="handleRefreshLabel"
            >
                <template  slot="action-btn" slot-scope="{ index, item }" >
                    <!-- v-if="item.material_info.media_type==='image'" -->
                    <!-- <div class="play" @click="handleDownload(index, item)" >下载</div> -->
                    <div v-if="item.add_to_plan_flag && checkAllGroup.length === 0" :class="[item.material_type.material_type==='template' || item.material_type.material_type==='data_template'?'play':'play play2', 'pointer']" @click="handlePaly(item)" >立即投放</div>
                    <div v-if="(item.material_type.material_type==='template' || item.material_type.material_type==='data_template') && checkAllGroup.length === 0" class="edit pointer" @click="handleEditTmpl(item.rowid, item.material_type.material_type)">编辑</div>
                </template>
                <Dropdown
                    v-allow="'content'"
                    v-if="checkAllGroup.length === 0"
                    class="more"
                    placement="bottom-start"
                    trigger="click"
                    slot-scope="{ index, item }"
                    slot="dropdown" >
                    <a href="javascript:void(0)">
                        <div class="md-more"><Icon class="icon" type="md-more" size="20" /></div>
                    </a>
                    <DropdownMenu slot="list" >
                        <DropdownItem v-if="item.material_type.material_type==='template' || item.material_type.material_type==='data_template'" @click.native="handleCopyToCurrent([item.rowid])">创建副本</DropdownItem>
                        <DropdownItem @click.native="handelLabel([item.rowid])">编辑标签</DropdownItem>
                        <DropdownItem @click.native="handleRename(index)">重命名</DropdownItem>
                        <DropdownItem @click.native="handleRemove([item.rowid])">删除</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </spaces-common>
        </div>
        <edit-label
            :show="labelModalShow"
            :material_ids="material_ids"
            :bucket_token="bucket_token"
            @confirm="handleLabelConfirm"
            @cancel="handleLabelCancel"
            @refresh="handleRefresh"
        ></edit-label>
        <uploadModal
            ref="upload"
            :show="uploadModalShow"
            :bucket_token="bucket_token"
            @confirm="handleUploadConfirm"
            @cancel="handleUploadCancel"
            @show="handleUploadModal"
            @refresh="handleRefreshLabel"
        ></uploadModal>
        <playSetting
            :show="playModalShow"
            :materialInfo="materialInfo"
            @confirm="handlePlayConfirm"
            @cancel="handlePlayCancel"
        >
            <div class="material-block" slot="material" :slot-scope="{ materialInfo }">
                <div class="img-wrap">
                    <img v-if="materialInfo.uri" :class="['image-center', materialInfo.material_type==='template'||materialInfo.material_type==='data_template'?'template-preview-image-bkg':'']" :src="`${materialInfo.uri}/thumbnail`">
                </div>
                <div class="info">
                    <p>素材名称：{{ materialInfo.name }}</p>
                    <p>分辨率：{{ materialInfo.resolution }}</p>
                    <p v-if="materialInfo.bytes_size">素材大小：{{ materialInfo.bytes_size }}</p>
                </div>
            </div>
        </playSetting>
        <label-mag-modal
            :show="labelMagModalShow"
            :bucket_token="bucket_token"
            @cancel="handleLabelMagCancel"
            @refresh="handleRefreshLabel"
            @clear="clearLabelList"
        ></label-mag-modal>
    </div>
</template>
<script>
    import editLabel from './components/editLabel'
    import uploadModal from 'views/components/upload'
    import spacesCommon from './components/spacesCommon'
    import playSetting from 'views/components/playSetting'
    import labelMagModal from './components/labelMag'
    import noData from '@/views/components/noData'
    import loadMore from '@/views/components/loadMore'
    import utils from '@/libs/util.js'
    import { checkbox, dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [checkbox, dropdownTree],
        components: {
            editLabel,
            uploadModal,
            spacesCommon,
            playSetting,
            labelMagModal,
            noData,
            loadMore
        },
        props: [],
        data () {
            return {
                total: 0,
                current: 1,
                pageSize: 36,
                labelModalShow: false,
                uploadModalShow: false,
                playModalShow: false,
                selected: new Set(),
                query: '',
                list: [],
                loading: false,
                indeterminate: false,
                checkAll: false,
                checkAllGroup: [],
                //素材分类
                materialClass: "all",
                //素材类型详情
                typeList: [],
                //模版类型
                templateTypeList: [],
                type: 'all',
                materialInfo: {},
                label: null,
                labelList: [],
                labelMagModalShow: false,
                material_ids: [],
                labelPageSize: 10,
                labelCurrent: 1,
                model1: [],
                nodeList: [],
                data: [],
                treeList: []
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens.org
            },
            dist_bucket_token() {
                return this.$store.state.default_bucket_tokens.user
            },
            tree_data() {
                return [this.$store.state.up_tree]
            }
        },
        methods: {
            searchNew() {
                if (this.loading == true) return
                this.current = 1
                this.getData()
            },
            handleRemove(list) {
                this.$Modal.confirm({
                    title: '删除提示',
                    content: '删除资源后将无法重新找回，请谨慎操作！',
                    okText: '确定删除',
                    cancelText: '取消',
                    onOk: () => {
                        let newList = [...list],
                            len = newList.length,
                            str = ''
                        for (let i = 0; i < len; i++) {
                            str += newList[i]
                            if (i !== len - 1) {
                                str += ','
                            }
                        }
                        this.$api.delete(`materials/${str}?bucket_token=${this.bucket_token}`).then(()=>{
                            this.$Message.success(this.$t('message.success'));
                            this.checkAllGroup = []
                            this.indeterminate = false
                            this.checkAll = false
                            let len = list.length;
                            if(this.total - len === (this.current - 1) * this.pageSize && this.current > 1) {
                                this.current -= 1
                            }
                            this.getData()
                        })

                    }
                });
            },
            handleCopyToCurrent(list) {
                return this.$api.post('materials/copy', { rowid_list: list, dist_bucket_token: this.bucket_token }).then(() => {
                    this.checkAllGroup = []
                    this.indeterminate = false
                    this.checkAll = false
                    return this.getData()
                }).catch(() => {})
            },
            handelLabel(material_ids) {
                this.labelModalShow = true
                this.material_ids = material_ids
            },
            handleRefresh() {
                this.getData()
                this.handleRefreshLabel()
            },
            handleRefreshLabel() {
                this.labelCurrent = 1
                this.labelList =  []
                this.getLabelList()
            },
            handleLabelConfirm() {
                this.handleLabelCancel()
                this.checkAllGroup = []
                this.indeterminate = false
                this.checkAll = false
            },
            handleLabelCancel() {
                this.labelModalShow = false
            },
            handleUpload() {
                if (this.$refs['upload'].defaultList.length > 0) {
                    this.$refs['upload'].loadingModalShow = true
                    return
                }
                this.$el.querySelector('.upload').getElementsByTagName('input')[0].click()
                this.$refs['upload'].init = true
            },
            handleUploadModal () {
                this.uploadModalShow = true
            },
            handleUploadConfirm() {
                this.handleUploadCancel()
                this.current = 1
                this.getData()
            },
            handleUploadCancel() {
                this.uploadModalShow = false
            },
            handleRename(index) {
                var eles = document.querySelectorAll('.name')
                eles[index].click()
            },
            submitRename({ rowid, name }) {
                let params = {
                    name: name,
                    bucket_token: this.bucket_token
                }
                this.$api.put(`materials/${rowid}`, params).then(()=>{
                    this.$Message.success('成功')
                    this.getData()
                })
            },
            handleEditTmpl(rowid, templateType) {
                const { href } = this.$router.resolve({
                    name: "templateDesign",
                    query: {
                        state: 'edit',
                        rowid: rowid,
                        templateType: templateType
                    }
                });
                window.open(href, '_blank')
            },
            handleCopy(list) {
                let newList = [...list],
                    len = newList.length,
                    str = '';
                for (let i = 0; i < len; i++) {
                    str += newList[i]
                    if (i !== len - 1) {
                        str += ','
                    }
                }
                this.$api.post(`materials/copy?rowid_list=${str}&&dist_bucket_token=${this.dist_bucket_token}`).then(()=>{
                    this.$Message.success('成功')
                    this.getData()
                })
            },
            handlePaly(item) {
                let { rowid, name, uri, material_info } = item,
                    size = item.bytes_size/(1024*1024);
                this.materialInfo = {
                    type: 'material',
                    rowid: rowid,
                    name: name,
                    uri: `/cym/${uri}`,
                    resolution: `${material_info.resolution[0]}x${material_info.resolution[1]}PX`,
                    bucket_token: item.bucket_token,
                    material_type: item.material_type.material_type,
                    category: material_info.category?material_info.category:'',
                    target_size: material_info.target_size
                }
                if (item.material_type.material_type !== 'template' && item.material_type.material_type !== 'data_template') {
                    if (size >= 1) {
                        this.materialInfo.bytes_size = Math.round(size*10)/10 + 'M'
                    } else if (size >= 0 && size < 1) {
                        this.materialInfo.bytes_size =  Math.round(10*item.bytes_size/1024)/10 + 'KB'
                    }
                }
                this.playModalShow = true
            },
            handlePlayConfirm() {
                this.handlePlayCancel()
            },
            handlePlayCancel() {
                this.playModalShow = false
            },
            handleDownload(item) {
                utils.handleDownload('/cym/'+item.uri, item.name)
            },
            getData() {
                let params = {
                        query: this.query,
                        start: (this.current - 1) * this.pageSize,
                        count: this.pageSize
                    },
                    len = this.nodeList.length;
                if (len) {
                    let bucket_token_list = []
                    this.nodeList.map((item) => {
                        bucket_token_list.push(item.bucket_token)
                    })
                    params.bucket_token_list = JSON.stringify(bucket_token_list)
                } else {
                    this.treeList = []
                    this.getTreeList(this.tree_data, 'bucket_token')
                    params.bucket_token_list = JSON.stringify(this.treeList)
                }
                if (this.type !== 'all') {
                    params.type_list = this.type
                }
                if (this.label) {
                    params.label_id = this.label.rowid
                }
                this.loading = true
                //解决翻页后，显示上页勾选状态问题
                let store = [...this.checkAllGroup]
                this.checkAllGroup = []
                this.checkAll = false;

                return this.$api.get('materials/search', { params }).then(({ data, total_count, page })=>{
                    if (page === this.current) {
                        let isAll = true
                        let set = new Set(store)
                        //检测是否当页全选，当前页全勾选，显示全选状态
                        data.map((item)=>{
                            if (!set.has(item.rowid) || store.length == 0) {
                                isAll = false
                            }
                        })
                        if (isAll) {
                            this.indeterminate = false;
                            this.checkAll = true;
                        }

                        this.list = data
                        this.total = total_count
                        this.loading = false
                        return data
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{
                    this.checkAllGroup = store
                })
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            async getTypeList(e) {
                let url = '';
                this.type = 'all'
                switch(e) {
                    case 'all':
                        url = 'materials/type_list';
                        break;
                    case 'templates':
                        return;
                    case 'materials':
                        url = 'materials/media_type_list';
                        break;
                }
                await this.$api.get(url).then(({ data }) => {
                    this.typeList = data
                })
                this.getData()
            },
            handleTypeList() {
                this.current = 1
                this.getData()
            },

            getTemplateTypeList() {
                this.$api.get('materials/template_type_list').then(({ data }) => {
                    this.templateTypeList = data
                })
            },
            handleTemplateType(typeList) {
                this.typeList = typeList
            },
            getLabelList() {
                let params = {
                    bucket_token: this.bucket_token,
                    start: (this.labelCurrent - 1) * this.labelPageSize,
                    count: this.labelPageSize
                }
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.labelCurrent += 1
                        this.labelList.push(...data)
                    }
                    if (this.labelList.length == total_count) {
                        this.isLoaded = true
                    } else {
                        this.isLoaded = false
                    }
                })
            },
            clearLabelList() {
                this.current = 1
                this.label = ''
                this.getData()
            },
            handleLabelList(label) {
                this.current = 1
                this.label = label
                this.getData()
            },
            handleLabelMag() {
                this.labelMagModalShow = true
            },
            handleLabelMagCancel() {
                this.labelMagModalShow = false
            },
            handleCreateTmpl() {
                const { href } = this.$router.resolve({
                    name: "templateDesign",
                    query: {
                        state: 'add',
                        templateType: 'template'
                    }
                });
                window.open(href, '_blank')
            },
            async handleReachBottom(done) {
                await this.getLabelList()
                done()
            }
        },
        async mounted() {
            await this.getTemplateTypeList()
            this.getTypeList('all')
            this.getLabelList()
            // this.getData()
            this.data  = this.handleRecursive(this.tree_data)
        }
    }
</script>
<style scoped lang="less">
.material-block {
    margin-bottom: 23px;
    height: 166px;
    .img-wrap {
        display: inline-block;
        position: relative;
        margin-right: 20px;
        height: 166px;
        width: 295px;
        background: #cfcfcf;
    }
    .info {
        display: inline-block;
        vertical-align: bottom;
        font-weight: 700;
    }
}
#company-spaces {
    .action-bar {
        .radio-group-button {
            margin-left: -15px;
            position: relative;
            &::after {
                content: '\200B';
                position: absolute;
                top: 50%;
                right: -10px;
                width: 1px;
                height: 15px;
                background: #b4b9bf;
                transform: translateY(-50%);
            }
        }
        .dropdown {
            position: relative;
            margin-left: 20px;
            vertical-align: bottom;
            .close {
                display: none;
            }
            .label-mag {
                cursor: pointer;
                padding: 7px 0 7px 4px;
                font-size: 14px;
            }
            &:hover {
                .close {
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
            .tree {
                display: inline-block;
                max-width: 100px;
                vertical-align: bottom;
            }
            .tree {
                display: inline-block;
                max-width: 100px;
                vertical-align: bottom;
            }
        }
        .dropdown-structure {
            margin-left: 0;
        }
        .dropdown-label {
            .tooltip {
                display: block;
                width: 100%;
                .label {
                    max-width: 179px;
                }
            }
        }
        .dropdown-type {
            margin-left: 20px;
        }
    }
}
</style>